@use "../../../premmio/public/styles/breakpoints";
@use "clientSlider";

#employeeOfTheMonth {
  text-align: center;
  margin: 0;

  h2 {
    display: inline-block;
    font-size: var(--fs10);

    text-transform: uppercase;
    padding: 1rem 1.5rem;
    letter-spacing: 4px;
    margin-bottom: 0;
  }

  .employeeGrid {
    display: flex;
    flex-wrap: wrap;

    .employee {
      flex: 0 0 32.5%;
      padding: 0.25rem;
      box-sizing: border-box;
      opacity: 0;

      figure {
        width: 100%;
        background: var(--light-900);
        box-sizing: border-box;
        box-shadow: var(--box-shadow);

        img {
          width: 100%;
        }

        figcaption {
          padding: 0 0.25rem 0.25rem;
        }

        h3 {
          font-size: var(--fs12);

          margin: 0;
          font-weight: 400;
          color: var(--dark-400);
          text-transform: uppercase;
          letter-spacing: 1px;
        }

        // p {
        //   font-size: var(--fs11);
        //
        //   margin: 0;
        //   color: var(--dark-800);
        //   font-weight: 700;
        // }
      }
    }

    &:not(.animating) {
      .employee {
        animation: none !important;
      }
    }
  }
}

#clientShowcase {
  overflow: hidden;
}

#premmio {
  overflow: hidden;

  #premmioLogo {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;

    svg {
      height: 6rem;
    }
  }
}

#premmioScreenshots {
  position: relative;
  opacity: 0;
  min-height: 20rem;

  img {
    height: calc(100% + 2rem);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &.animating {
    animation: fadeInLeft 1s forwards;
  }
}

@media (width > breakpoints.$md) {
  #employeeOfTheMonth {
    .employeeGrid {
      .employee {
        flex: 0 0 24.5%;
      }
    }
  }
}
